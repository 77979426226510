import React, {useEffect} from "react";
import {NextButton} from "./NextButton";
import CrossfadeImage from 'react-crossfade-image'
import { useTranslation } from 'react-i18next';
import FadeIn from 'react-fade-in';
// import ReactGA from './GoogleAnalytics';

import groupImg1 from "./img/userGroups/460-less_active.jpg"
import groupImg2 from "./img/userGroups/460-moderately_active.jpg"
import groupImg3 from "./img/userGroups/460-moderately_active_motivated.jpg"
import groupImg4 from "./img/userGroups/460-active.jpg"
import groupImg5 from "./img/userGroups/460-highly_active.jpg"

const userImgs = [
    groupImg1, // FIXME need a default image for no selection..?
    groupImg1,
    groupImg2,
    groupImg3,
    groupImg4,
    groupImg5,
]

function SelectUserGroup(props) {
    // ReactGA.pageview('select-user-group')
    const { t } = useTranslation()

    // preloading image
    useEffect(() => userImgs.forEach((src) => new Image().src = src), []);
    const {selected} = props
    const img = userImgs[props.userGroups.indexOf(selected)+1]

    return (
        <div className={'mainBody'}>
            <div className={'userGroupSection'}>
                <div className={'userGroupImg'}>
                    <CrossfadeImage alt='user group' src={img}/>
                </div>
                <div className='optionContainer'>
                    <FadeIn>
                        {props.userGroups.map((g, i) => <div key={i}>
                            <UserGroup
                                isSelected={g === selected}
                                group={g}
                                onClick={props.onSelect}/>
                        </div>)
                        }
                    </FadeIn>
                </div>
            </div>
            <div className={'navigationButtons'}>
                <button className='helpButton'>{t('user-groups-help-button')}</button>
                <NextButton onClick={props.onNext} disabled={!selected}/>
            </div>
        </div>

    )
}

function UserGroup(props) {
    const {group} = props;

    return (
        <button
            className={(props.isSelected?'optionSelected ':'')+'optionSelector'}
            onClick={() => props.onClick(group)}>
            {group}
        </button>
    )
}

export default SelectUserGroup