import './App.css';
import SelectUserGroup from "./SelectUserGroup";
import SelectResidualLimb from "./SelectResidualLimb";
import Results from "./Results";
import SelectClinicalCondition from "./SelectClinicalCondition";

function MainBody(props) {

    if(props.pageNum===1) {

        return <SelectUserGroup
            selected={props.group}
            userGroups={props.userGroups}
            onSelect={props.onSelectGroup}
            onNext={props.onNext}
        />
    }

    if(props.pageNum===2) {
        return (
            <div>
                <SelectResidualLimb
                    shape={props.shape}
                    shapes={props.shapes}
                    onSelectShape={props.onSelectShape}

                    condition={props.condition}
                    conditions={props.conditions}
                    onSelectCondition={props.onSelectCondition}

                    length={props.length}
                    onSelectLength={props.onSelectLength}
                    lengths={props.lengths}

                    onNext={props.onNext}
                    onBack={props.onBack}
                />
            </div>
        )
    }

    if(props.pageNum===3) {

        return <SelectClinicalCondition
            selected={props.clinicalCondition}
            clinicalConditions={props.clinicalConditions}
            onSelect={props.onSelectClinicalCondition}
            onNext={props.onNext}
            onBack={props.onBack}
        />
    }

    if(props.pageNum===4) {
        return <Results
            results={props.results}
            onBack={props.onBack}
        />
    }

    return `unknown pageNum: ${props.pageNum}`
}

export default MainBody;