import React from "react";
import cylindricalImg from './img/shapes/cylindrical.png'
import conicalImg from './img/shapes/conical.png'

function SelectShape(props) {

    return (
        <div className={'selectShape'}>
                {props.shapes.map((s, i) => <div key={i}>
                    <Shape isSelected={s === props.selected} shape={s} onSelect={props.onSelect}/>
                </div>)
                }
        </div>
    )
}

function getShapeImg(shape) {
    if(shape==='Cylindrical' || shape==='Zylindrisch') {
        return cylindricalImg
    } else {
        return conicalImg
    }
}

function Shape(props) {
    const {shape} = props;

    return (
        <button
            className={props.isSelected?'shape-button-selected':'shape-button'}
            onClick={() => props.onSelect(shape)}>
            <img className={'shapeImg'} alt={shape} src={getShapeImg(shape)}/>
            <div className={'shape-button-label'}>{shape}</div>
        </button>
    )
}

export default SelectShape