import React from 'react';
import Papa from 'papaparse';

const DELIM = ';'

function deleteRecommendationIfEmpty(recommendations) {

    for(let i in recommendations) {
        // noinspection JSUnfilteredForInLoop
        recommendations[i].product=deleteEmptyProducts(recommendations[i].product)
    }

    if(recommendations[1].product.length===0) {
        recommendations.splice(1,1)
    }
}

function deleteEmptyProducts(products) {
    return products.filter(p=>p.code || p.name || p.img || p.url);
}

export function cleanUpData(orig) {
    // TODO use first two rows for language strings
    let [, , ...data] = orig.data
    let clean=[]

    for(let l of data) {
        let line = {
            id:        l[ 0],
            userGroup: capitalizeFirstChar(l[ 1]),
            shape:     capitalizeFirstChar(l[ 2]),
            condition: capitalizeFirstChar(l[ 3]),
            length:    capitalizeFirstChar(l[ 4]),
            clinicalCondition:  capitalizeFirstChar(l[ 5]),
            recommendation: [
                {
                    product: [
                        {
                            code: l[ 6],
                            name: l[ 7],
                            img:  l[ 8],
                            url:  l[ 9]
                        },
                        {
                            code: l[10],
                            name: l[11],
                            img:  l[12],
                            url:  l[13]
                        },
                        {
                            code: l[14],
                            name: l[15],
                            img:  l[16],
                            url:  l[17]
                        },
                        {
                            code: l[18],
                            name: l[19],
                            img:  l[20],
                            url:  l[21]
                        }
                    ],
                    resultsPageSentence: l[22]

                },
                {
                    product: [
                        {
                            code: l[23],
                            name: l[24],
                            img:  l[25],
                            url:  l[26]
                        },
                        {
                            code: l[27],
                            name: l[28],
                            img:  l[29],
                            url:  l[30]
                        },
                        {
                            code: l[31],
                            name: l[32],
                            img:  l[33],
                            url:  l[34]
                        },
                        {
                            code: l[35],
                            name: l[36],
                            img:  l[37],
                            url:  l[38]
                        }
                    ],
                    resultsPageSentence: l[39]
                },
            ]
        };

        deleteRecommendationIfEmpty(line.recommendation)

        clean.push(line)
    }
    return { data: clean }
}

export function parseCsv(csvData, onDataLoaded) {
    Papa.parse(csvData, {
        // header: true,
        delimiter: DELIM,
        skipEmptyLines: 'greedy',
        transform: t => t.trim(),
        complete: onDataLoaded
    });
}

export class CsvReader extends React.Component {

    componentDidMount() {
        this.getCsvData().then(() => console.log('loaded csv'));
    }

    fetchCsv() {
        return fetch(`data/Logic selection tool-${this.props.language}.csv`)
            .then(response => response.text())
    }

    async getCsvData() {
        let csvData = await this.fetchCsv();

        parseCsv(csvData, this.props.onDataLoaded)
    }

    render() {
        return null
    }
}

const capitalizeFirstChar = str => str?str.charAt(0).toUpperCase() + str.substring(1):str