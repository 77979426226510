import React from "react";
import SelectShape from "./SelectShape";
import SelectCondition from "./SelectCondition";
import SelectLength from "./SelectLength";
import {NextButton} from "./NextButton";
import {BackButton} from "./BackButton";
import FadeIn from "react-fade-in";
// import ReactGA from "./GoogleAnalytics";

function SelectResidualLimb(props) {
    // ReactGA.pageview('select-residual-limb')

    const conditions = props.conditions;
    const lengths = props.lengths;

    const shape = props.shape
    const condition = props.condition
    const length = props.length

    const showCondition = conditions && conditions.length > 0
    // const showLength = length && length.length > 0

    const allSelected =
        shape &&
        (conditions.length === 0 || condition) &&
        (lengths.length === 0 || length)

    return (
        <div className={'mainBody'}>
            <FadeIn className={'selectResidualLimb'}>
                <SelectShape
                    selected={shape}
                    shapes={props.shapes}
                    onSelect={props.onSelectShape}/>

                {conditions.length > 0 || lengths.length > 0 ?
                    <div className={'selectLengthAndCondition'}>

                        {lengths.length > 0
                            ? <SelectLength
                                selected={length}
                                lengths={lengths}
                                onSelect={props.onSelectLength}/>
                            : ''
                        }
                        <SelectCondition
                            hidden={ !showCondition }
                            selected={condition}
                            conditions={conditions}
                            onSelect={props.onSelectCondition}/>

                    </div>
                    : ''}
            </FadeIn>
            <div className={'navigationButtons'}>
                <BackButton onClick={props.onBack}/>
                <NextButton
                    onClick={props.onNext}
                    disabled={!allSelected}/>
            </div>
        </div>
    )
}

export default SelectResidualLimb