import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// import i18n (needs to be bundled ;))
import './i18n';

import TagManager from 'react-gtm-module'
// import {DownloadPdf} from "./DownloadPdf";

TagManager.initialize({gtmId: 'GTM-WB684JM'})

ReactDOM.render(
  <React.StrictMode>
      {/*{isDownloadPdf()?<DownloadPdf/>:<App/>}*/}
      <App/>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// function isDownloadPdf() {
//     return false // Moved this logic into App.js
//     // return getSearchParam('download')!=null
// }