import React from "react";
import { useTranslation } from "react-i18next";

function LanguageSwitcher(props) {
    const { i18n } = useTranslation();

    return (
        <div className="select">
            <select
                value={i18n.language}
                onChange={(e) =>
                    props.onChange(e.target.value)}>
                <option value="en">English</option>
                <option value="de">Deutsche</option>
            </select>
        </div>
    )
}

export default LanguageSwitcher;