import './App.css';

import {Component, Suspense} from "react";
import {cleanUpData, CsvReader} from "./CsvReader";
import MainBody from "./MainBody";
import {pageNames} from "./Strings";
import {withTranslation} from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";
import i18n from "i18next";
import FormCycle from "./FormCycle";
import {getSearchParam} from "./util";
import {SplashPage} from "./SplashPage.js";

function showLanguageSelector() {
    return getSearchParam('lang') === ''

}

function isFormCycleEnabled() {
    return getSearchParam('formcycle') != null
}

// function isDownloadPdf() {
//     return getSearchParam('download')!=null
// }

class LegacyComponentClass extends Component {

    constructor(props) {
        super(props);

        this.state = {
            language: i18n.language,
            data: [],
            userGroups: [],
            dataLoaded: false,
            group: null,
            shape: null,
            condition: null,
            length: null,
            clinicalCondition: null,
            pageNum: 0,
            displayedPages: [],
            showSplashPage: true
        };

        this.handleDataLoaded = this.handleDataLoaded.bind(this);

        this.calculatePages = this.calculatePages.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.handleBack = this.handleBack.bind(this);

        this.handleLanguageChange = this.handleLanguageChange.bind(this);
        this.handleSelectGroup = this.handleSelectGroup.bind(this);

        this.handleInitResidualLimb = this.handleInitResidualLimb.bind(this);
        this.handleSelectShape = this.handleSelectShape.bind(this);
        this.handleSelectCondition = this.handleSelectCondition.bind(this);
        this.handleSelectLength = this.handleSelectLength.bind(this);

        this.handleInitClinicalConditions = this.handleInitClinicalConditions.bind(this);
        this.handleSelectClinicalCondition = this.handleSelectClinicalCondition.bind(this);
        this.handleInitResults = this.handleInitResults.bind(this);

    }

    onResize() {
        // Obviously you can format your data object however you want
        const message = {
            'message-id': 'ttSelectorApp',
            height: document.body.scrollHeight,
            // width: document.body.scrollWidth,
        };
        // console.log('resize message:', message)
        if (message.height > 5)
            window.parent.postMessage(message, '*');
    }

    componentDidMount() {
        this.onResize();  // Invoke the handler manually once initially
        (new ResizeObserver(_ =>
            this.onResize()
        )).observe(document.body);
    }

    resetState(state) {
        const pageNum = state.pageNum;

        if (pageNum < 4) {
            this.setState({
                results: undefined,
                displayedPages: [1, 2, 3]

            })
        }

        if (pageNum < 3) {
            this.setState({
                clinicalCondition: undefined,
                clinicalConditions: undefined,
                displayedPages: [1, 2]
            })
        }

        if (pageNum < 2) {
            this.setState({
                condition: undefined,
                conditions: undefined,
                length: undefined,
                lengths: undefined,
                shape: undefined,
                shapes: undefined,
                displayedPages: [1]
            })
        }

        if (pageNum < 1) {

            this.setState({
                group: undefined,
                userGroups: undefined,
                displayedPages: []
            })
        }
    }

    handleLanguageChange(language) {
        i18n.changeLanguage(language).then(() => {
            this.setState({language, pageNum: 0})
            this.resetState(this.state)
        })
    }

    handleSelectGroup(group) {
        this.resetState(this.state)
        this.setState({group})
    }

    handleSelectShape(shape) {
        this.resetState(this.state)
        this.setState({shape})
    }

    handleSelectCondition(condition) {
        this.resetState(this.state)
        this.setState({condition})
    }

    handleSelectLength(length) {
        this.resetState(this.state)
        this.setState({length})

        const group = this.state.group

        let matches = this.state.data.filter(
            d => d.userGroup === group && d.length === length
        );
        const conditions = [...new Set(matches
            .map(s => s.condition))]
            .filter(l => l);
        this.setState({conditions})
    }

    handleSelectClinicalCondition(clinicalCondition) {
        this.resetState(this.state)
        this.setState({clinicalCondition})
    }

    handleInitResidualLimb() {
        const group = this.state.group

        let matches = this.state.data.filter(d => d.userGroup === group);

        const shapes = [...new Set(matches.map(s => s.shape))];
        const conditions = [...new Set(matches.map(s => s.condition))].filter(l => l);
        const lengths = [...new Set(matches.map(s => s.length))].filter(l => l);

        // set length to middle option by default
        let length = this.state.length
        if (typeof length === 'undefined' || length === null) {
            length = lengths[1]
        }

        // set condition to  to middle option by default
        let shape = this.state.shape
        if (typeof shape === 'undefined' || shape === null) {
            shape = shapes[0]
        }

        this.setState({
            shape,
            shapes,
            conditions,
            lengths,
            length,
            pageNum: 2,
            displayedPages: [1, 2]
        })

    }

    handleInitClinicalConditions() {

        const clinicalConditions = [...new Set(this.state.data
            .filter(d => d.userGroup === this.state.group)
            .filter(d => this.state.shapes.length === 0 || d.shape === this.state.shape)
            .filter(d => this.state.conditions.length === 0 || d.condition === this.state.condition)
            .filter(d => this.state.lengths.length === 0 || d.length === this.state.length)
            .map(s => s.clinicalCondition))]
            .filter(d => d)

        if (clinicalConditions.length === 0) {
            this.setState({clinicalConditions: undefined})
            this.handleInitResults()
        } else {
            this.setState({
                clinicalConditions,
                pageNum: 3,
                displayedPages: [1, 2, 3]
            })
        }
    }

    handleInitResults() {

        const {clinicalCondition, data, length, shape, group, clinicalConditions, condition, conditions} = this.state;

        const results = [...new Set(data
            .filter(d => d.userGroup === group)
            .filter(d => d.shape === shape)
            .filter(d => conditions.length === 0 || !condition || d.condition === condition)
            .filter(d => !length || d.length === length)
            .filter(d => !clinicalCondition || d.clinicalCondition === clinicalCondition)
        )]

        this.setState({
            results,
            pageNum: 4,
        })

        if (typeof clinicalConditions !== 'undefined') {
            this.setState({
                displayedPages: [1, 2, 3, 4]
            })

        } else {
            this.setState({
                displayedPages: [1, 2, 4]
            })
        }
    }

    handleDataLoaded(csv) {
        csv = cleanUpData(csv)
        const userGroups = [...new Set(csv.data.map(d => d.userGroup))].filter(d => typeof d !== 'undefined')
        this.setState({
            data: csv.data,
            csv: csv,
            userGroups,
            dataLoaded: true,
            pageNum: 1,
            displayedPages: this.calculatePages()
        });
    }

    handleNext() {
        switch (this.state.pageNum) {
            case 1:
                return this.handleInitResidualLimb()
            case 2:
                return this.handleInitClinicalConditions()
            case 3:
                return this.handleInitResults()
            default:
                break;
        }
    }

    handleBack() {
        if (this.state.pageNum === 4 && (typeof this.state.clinicalConditions) == 'undefined') {
            this.setState({pageNum: 2})
        } else {
            this.setState({pageNum: this.state.pageNum - 1})
        }
    }

    calculatePages() {
        let displayedPages = [1]

        if (this.state.shape || this.state.conditions || this.state.lengths) displayedPages.append(2)

        return displayedPages
    }

    handleGotoPageNum(pageNum) {
        this.setState({pageNum})
    }

    render() {
        const {t} = this.props

        // if(isDownloadPdf()) {
        //     return <DownloadPdf/>
        // }

        if (this.state.pageNum === 0) {
            return <CsvReader language={this.state.language} onDataLoaded={this.handleDataLoaded}/>
        }

        if (this.state.showSplashPage) {
            return (
                <div className={'app'}>
                    <div className={'headLiner'}>{t('tt-selection-tool')}</div>
                    {showLanguageSelector() ? <LanguageSwitcher onChange={this.handleLanguageChange}/> : ''}
                    <SplashPage onNext={() => this.setState({showSplashPage: false})}/>
                </div>
            )
        }

        return (
            <div className={'app'}>
                {isFormCycleEnabled() ? <FormCycle/> : ''}
                <div className={'headLiner'}>{t('tt-selection-tool')}</div>
                {showLanguageSelector() ? <LanguageSwitcher onChange={this.handleLanguageChange}/> : ''}

                <div className={'tabs'}>
                    {this.state.displayedPages.map(p => (
                        <button key={p}
                                className={this.state.pageNum === (p) ? 'tab tabLinerOn' : 'tab tabLinerOff'}
                                onClick={() => this.handleGotoPageNum(p)}>{t(pageNames[p])}</button>
                    ))}
                </div>
                <MainBody
                    pageNum={this.state.pageNum}
                    onNext={this.handleNext}
                    onBack={this.handleBack}

                    group={this.state.group}
                    userGroups={this.state.userGroups}
                    onSelectGroup={this.handleSelectGroup}

                    shape={this.state.shape}
                    shapes={this.state.shapes}
                    onSelectShape={this.handleSelectShape}

                    condition={this.state.condition}
                    conditions={this.state.conditions}
                    onSelectCondition={this.handleSelectCondition}

                    length={this.state.length}
                    lengths={this.state.lengths}
                    onSelectLength={this.handleSelectLength}

                    clinicalCondition={this.state.clinicalCondition}
                    clinicalConditions={this.state.clinicalConditions}
                    onSelectClinicalCondition={this.handleSelectClinicalCondition}

                    results={this.state.results}
                />
            </div>
        )
    }
}

const MyComponent = withTranslation()(LegacyComponentClass)

// i18n translations might still be loaded by the http backend
// use React's Suspense
export default function App() {
    return (
        <Suspense fallback="loading">
            <MyComponent/>
        </Suspense>
    );
}