import React from "react";
import { useTranslation } from 'react-i18next';

function SelectLength(props) {
    const { t } = useTranslation();

    const lengths = props.lengths
    const selectedIdx = lengths.indexOf(props.selected)

    return (
        <div>
            <label className={'selectLengthLabel'}>{t('length')}</label>
            <br/>
            <input
                className={'selectLength'}
                type="range"
                min="0" max={lengths.length - 1}
                value={selectedIdx}
                onChange={(e) => props.onSelect(lengths[e.target.value])}
                step="1"/>

                <div className={'selectLengthsLabels'}>
                    <div>{lengths[0]}</div>
                    <div>{lengths[1]}</div>
                    <div>{lengths[2]}</div>
                </div>
        </div>
    )
}

export default SelectLength