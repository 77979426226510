import React, {useState} from "react"
// import {downloadPDF} from "./GeneratePDF";
import productImg0 from './img/products/0.jpg'
import productImg1 from './img/products/1.jpg'
import productImg2 from './img/products/2.jpg'
import productImg3 from './img/products/3.jpg'
import circlePlus from './img/circle-plus.svg'
// import pdfFileIcon from './img/icons/pdf-file.jpg'
import { useTranslation } from 'react-i18next';
// import ReactGA from "./GoogleAnalytics";
import FormCycle from "./FormCycle";

function Results(props) {
    // ReactGA.pageview('results')

    const { t } = useTranslation();

    const [recNum, setRecNum] = useState(0)
    const results = props.results

    if (results.length !== 1) {
        return `Error: expected 1 result, got ${results.length}!`
    }

    const result = results[0];
    // const userGroupLc = result.userGroup.toLowerCase()

    const recs = result.recommendation


    return (
        <div className={'mainBody resultsPage'}>
            <div className={'recommendationButtons'}>
                {recs.map((rec, i) => (
                    <button
                        key={i}
                        className={'recommendationButton'}
                        disabled={i === recNum}
                        onClick={() => setRecNum(i)}>
                        {t('recommendation')} #{i + 1}
                    </button>)
                )}
            </div>
            {/*<div className={'recommendationIntro'}>{t('perfect-solution-for', { userGroup: userGroupLc })}</div>*/}

            <div className={'recommendationViewport'}>
            {
                result.recommendation.map((rec, i) => (
                    <div className={'recommendation'+(recNum+1)}>
                        <div className={'recommendationHeader'}>Recommendation #{i+1}</div>
                        <Recommendation key={i} recommendation={rec}/>
                    </div>
                ))
            }
            </div>
            <div className={'downloadPdf'}>
                {/*<BackButton onClick={props.onBack}>Back</BackButton>*/}
                <div>
                    <h2>{t('get-copy-of-results-heading')}</h2>
                    <label>{t('get-copy-of-results')}</label>
                </div>
                <FormCycle result={result}/>

                {/*<button className={'downloadPdfButton'} onClick={() => downloadPDF(result)}>*/}
                {/*    <img alt={'pdf icon'} src={pdfFileIcon}/>*/}
                {/*    {t('download-product-overview')}*/}
                {/*</button>*/}
            </div>
            <div className={'disclaimer'}>{t('results-disclaimer')}</div>
        </div>
    )
}

function Recommendation(props) {
    const {product: products, resultsPageSentence} = props.recommendation

    return (
        <div>
            <div className={'resultsPageSentence'}>
                {resultsPageSentence}
            </div>

            <div className={'recommendation'}>
                <Product productImg={productImg0} product={products[0]}/>
                <BigPlus/>
                <Product productImg={productImg1} product={products[1]}/>
                <BigPlus/>
                <Product productImg={productImg2} product={products[2]}/>
                {products.length > 3 ? <BigPlus/>:''}
                {products.length > 3 ? <Product productImg={productImg3} product={products[3]}/>:''}
            </div>
        </div>
    )

}

function BigPlus() {
    return (
        <img alt='divider' className={'circlePlus'} src={circlePlus}/>
    )
}

function Product(props) {
    const { t } = useTranslation();
    const {code, img, name, url} = props.product
    const imgFixed = img.replace(/=/,'_')

    let loadedImg

    try {
        loadedImg=require(`./img/product_small/${imgFixed}.jpg`).default
    } catch (e) {
        loadedImg=`file_does_not_exist_./img/product_small/${imgFixed}.jpg`
        console.error(loadedImg)
    }

    return (
        <div className={'product'}>
            <div className={'productContent'}>
                <img alt={name} src={ loadedImg }/>
                <div className={'productDetails'}>
                    <div className={'productCode'}>{code}</div>
                    <div className={'productName'}>{name}</div>
                    { url ?
                        <a className={'productLink'}
                           href={url}
                           target={'_blank'}
                           rel="noreferrer noopener">
                            {t('product-details')} <b>&gt;</b>
                        </a> : ''
                    }
                </div>
            </div>
        </div>
    )
}

export default Results