import React from "react";
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const customStyles = {
    option: (provided) => ({
        ...provided,
        color: '#757575',
        fontSize: 20,
        '&:hover': {
            backgroundColor: '#00157A',
            color: '#fff'
        },
        backgroundColor: '#fff',
    }),

    // this is the dropdown box
    menu: (provided) => ({
        ...provided,
        borderRadius: 0,
    }),
    control: (provided) => ({
        ...provided,
        borderRadius: 0,
        width: 454,
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        color: '#757575',
        fontSize: 20,
        borderBottom: '1px solid #5FA0AF',
        "&:selected": { backgroundColor: 'green' },
        "&:focus": { backgroundColor: 'red' },
        '@media only screen and (max-width: 959px)': {
            width: '100%',
            // paddingTop: '10px'
        }
        }),

    singleValue: (provided /*, state*/) => {
        const transition = 'opacity 300ms';
        return { ...provided,
            color: '#757575',
            transition };
    },
}

function SelectCondition(props) {
    const { t } = useTranslation();
    const { selected } = props;

    const options = props.conditions.map(c=> ({value: c,label: c}))
    const value = selected?{value: selected, label: selected}:undefined

    return (
        <div className={options.length === 0?"selectConditionHidden":"selectCondition"}>
            <label className={'selectConditionLabel'}>{t('condition')}</label>
            <br/>
            <Select styles={customStyles}
                    placeholder={t('choose-the-condition')}
                    options={options}
                    value={value}
                    onChange={(o)=>props.onSelect(o.value)}>
            </Select>
        </div>
    )
}

export default SelectCondition