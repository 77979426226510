import React from "react";
import {useTranslation} from "react-i18next";

// const formUrl = 'https://b2cforms.ottobock.com/form/alias/50/form2818/'
const formUrl = 'https://b2cforms.ottobock.com/form/provide/2959/'

function FormCycle(props) {
    const { i18n } = useTranslation()
    const result = props.result
    const searchParams = new URLSearchParams(
        {
            "xfc-height-changed-evt": "true",
            resultId: result.id,
            clinicalCondition: result.clinicalCondition,
            limbCondition: result.condition,
            limbShape: result.shape,
            userGroup: result.userGroup,
            limbLength: result.length,
            lang: i18n.language,
        }
    ).toString()

    console.log(searchParams)

    return <div>
        <iframe
            id="xm-form"
            title={'Email results'}
            src={formUrl+'?'+searchParams}
            className={'formCycle'}
            scrolling="no"
            frameBorder="0"/>
    </div>
}

export default FormCycle