import React from "react";
import {NextButton} from "./NextButton";
import {BackButton} from "./BackButton";
import FadeIn from 'react-fade-in';

import clinicalConditionImg from "./img/patient_indication.jpg"
// import ReactGA from "./GoogleAnalytics";

function SelectClinicalCondition(props) {
    // ReactGA.pageview('select-clinical-condition')

    const selected = props.selected
    const clinicalConditions = props.clinicalConditions

    return (
        <div className={'mainBody'}>
            <div className={'clinicalConditionSection'}>
                <img alt='clinical condition' className={'clinicalConditionImg'} src={clinicalConditionImg}/>
                <div className={'optionContainer'}>
                    {clinicalConditions.length === 0 ?
                        <div>No clinical conditions for selection</div> :
                            <FadeIn>
                                {props.clinicalConditions.map((g, i) =>
                                    <div key={i}>
                                    <ClinicalCondition
                                        isSelected={g === selected}
                                        group={g}
                                        onClick={props.onSelect}/>
                                </div>
                                )}
                            </FadeIn>
                        }
                </div>
            </div>
            <div className={'navigationButtons'}>

                <BackButton onClick={props.onBack}/>
                <NextButton onClick={props.onNext} disabled={
                    clinicalConditions.length > 0 && !selected
                }/>
        </div>
        </div>
    )
}

function ClinicalCondition(props) {
    const group = props.group;

    return (
        <div>
            <button
                className={(props.isSelected?'optionSelected ':'')+'optionSelector'}
                onClick={() => props.onClick(group)}>
                {group}
            </button>

        </div>
    )
}

export default SelectClinicalCondition