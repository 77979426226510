import {useTranslation} from "react-i18next";
import {StartButton} from "./StartButton.js";

import startPageImg from "./img/start_page.jpg"
import React from "react";

export function SplashPage(props) {
    const {t} = useTranslation();

    return (
        <div>
            <div className={'tabs'}>
                <div className='tab tabLinerOn'>&nbsp;</div>
            </div>

            <div className={'mainBody'}>
                <img alt='start page' className={'startPageImg'} src={startPageImg}/>

                <div className={'navigationButtons'}>
                    <button className='helpButton'>{t('user-groups-help-button')}</button>
                    <StartButton onClick={props.onNext}/>
                </div>
            </div>
        </div>
    )
}